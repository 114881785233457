<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h1 class="m-0">{{ title }} MVP</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>{{ title }}</th>
                </tr>
                </thead>

                <tbody>
                <tr v-if="!mvps.length && !fetchingData">
                  <td colspan="2">No data</td>
                </tr>

                <tr v-if="fetchingData">
                  <td colspan="2" class="text-center">
                    <div class="spinner-border" role="status">
                    </div>
                  </td>
                </tr>

                <template v-else>
                  <tr v-for="(mvp, i) in mvps" :key="'mvp_'+i">
                    <td>{{ mvp.name }}</td>
                    <td>
                      <span v-if="type === 'power'">{{ mvp.score }}</span>
                      <span v-if="type === 'wow'">{{ mvp.score.toFixed(2) }}%</span>
                      <span v-if="type === 'hourly_monty'">${{ mvp.score.toFixed(2) }}</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      mvps: [],
      fetchingData: false,
      type: this.$route.params.type,
      filters: {
        date: ''
      },
    }
  },
  watch: {
    $route() {
      this.loadData();
      this.type = this.$route.params.type;
    }
  },
  computed: {
    title() {
      if (this.$route.params.type === 'power') {
        return "Power Score"
      } else if (this.$route.params.type === 'wow') {
        return "Wow Score"
      } else if (this.$route.params.type === 'hourly_monty') {
        return "Hourly Monty"
      } else {
        return "";
      }
    }
  },
  mounted() {
    let start = window.moment().startOf('week');
    let end = window.moment();
    let self = this;

    window.$('#entry-date-range').daterangepicker(
        {
          autoUpdateInput: false,
          startDate: start,
          endDate: end,
          locale: {
            cancelLabel: 'Clear',
            format: 'MMM D, YYYY'
          },
          ranges: {
            'Today': [window.moment(), window.moment()],
            'This Week': [window.moment().startOf('week'), window.moment()],
            'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
            'This Quarter': [window.moment().quarter(window.moment().quarter()).startOf('quarter'), window.moment()],
            'Last Quarter': [window.moment().quarter(window.moment().quarter() - 1).startOf('quarter'), window.moment().quarter(window.moment().quarter() - 1).endOf('quarter')],
            'This Year': [window.moment().startOf('year'), window.moment()],
            'Last Year': [window.moment().subtract(1, 'years').startOf('year'), window.moment().subtract(1, 'years').endOf('year')],
            // 'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
            // 'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
            // 'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
          }
        },
        function (start, end) {
          self.changeDateRange(start, end);
        }
    )

    this.changeDateRange(start, end);
  },
  methods: {
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')

      this.loadData();
    },
    loadData() {
      this.mvps = [];
      this.fetchingData = true;
      this.filters.type = this.$route.params.type;

      this.axios.get('/mvp', {
        params: this.filters
      }).then((response) => {
        this.mvps = response.data.data;
      }).finally(() => {
        this.fetchingData = false;
      })
    }
  }
}
</script>